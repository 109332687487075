import { Component } from 'react';
import PropTypes from 'prop-types';
import BannerComponent from './BannerComponent';
import OverlayComponent from './OverlayComponent';
import classNames from 'classnames';
import {StoreContext} from "../../../../stores/StoreLoader";
import {observer} from "mobx-react";

@observer
class NotificationContainer extends Component {

    static contextType = StoreContext;

    static defaultProps = {
        notifications: []
    };

    static propTypes = {
        notifications: PropTypes.array.isRequired,
        heroIsEnabled: PropTypes.bool,
    };

    static localStorageKeys = {
        'banner': 'dismissedBanners',
        'overlay': 'dismissedOverlays',
        'notificationOnly': 'dismissedNotification',
    };

    static stateTypes = {
        'banner': 'shouldShowBanner',
        'overlay': 'shouldShowOverlay',
        'notificationOnly': 'shouldShowNotification',
    };

    constructor(props) {
        super(props);
        this.state = {
            shouldShowBanner: false,
            shouldShowOverlay: false,
        };

        this.bannerNotifications = [];
        this.overlayNotifications = [];

        this.handleDismiss = this.handleDismiss.bind(this);
    }

    handleDismiss(props) {
        const {alerts, type} = props;
        let notificationState = {};

        sessionStorage.setItem(NotificationContainer.localStorageKeys[type], JSON.stringify(alerts.map((alert) => alert.id)));

        notificationState[NotificationContainer.stateTypes[type]] = false;
        this.setState(notificationState);
    }

    componentDidMount(props) {
        // check local storage for both alert types
        const {notifications} = this.props;
        let newNotificationState = {},
            dismissedIds = {},
            storageKey;

        Object.keys(NotificationContainer.localStorageKeys).forEach((notificationType) => {
            storageKey = NotificationContainer.localStorageKeys[notificationType];
            dismissedIds[notificationType] = JSON.parse(sessionStorage.getItem(storageKey)) || [];
        });

        notifications.map((notification) => {
            switch (notification.type) {
                case 'overlay':
                    this.overlayNotifications.push(notification);
                    break;

                case 'banner':
                    this.bannerNotifications.push(notification);
                    break;
            }

            if (notification.type && dismissedIds[notification.type].indexOf(notification.id) === -1) {
                newNotificationState[NotificationContainer.stateTypes[notification.type]] = true;
            }
        });

        this.setState(newNotificationState);
    }

    render() {
        let overlay,
            banner;

        if (this.state.shouldShowBanner && this.props.shouldShowBanner) {
            banner = <BannerComponent alerts={this.bannerNotifications}
                                      handleDismiss={this.handleDismiss}/>;
        }

        if (this.state.shouldShowOverlay && this.props.shouldShowOverlay) {
            overlay = <OverlayComponent alerts={this.overlayNotifications} handleDismiss={this.handleDismiss}/>;
        }

        const notificationClassName = classNames({
            "sb-top-drawer-container": true,
            "sb-notification": true,
            "open": true,
        })

        const notificationContainerClassName = classNames({
            "notification-container": true,
            "notification-container-with-hero": this.props.heroIsEnabled,
            "banner-notification-container": this.state.shouldShowBanner && this.props.shouldShowBanner,
            "overlay-notification-container": this.state.shouldShowOverlay && this.props.shouldShowOverlay,
        })

        return (
            <div className={notificationContainerClassName}>
                <div id="sb-notification-drawer" className={"sb-top-drawer"} style={{visibility: "visible"}}>
                    <div ref={(notificationComponent) => {
                        this.notificationComponent = notificationComponent;
                    }}
                         className={notificationClassName}>
                        {banner}
                        {overlay}
                    </div>
                </div>
            </div>
        )
    }
}

export default NotificationContainer
