import {MutableRefObject, RefObject, useEffect} from 'react';

export default function useResizeEffect(ref: MutableRefObject<any>, effect: () => void, deps: any[] = []) {
    useEffect(() => {
            if (!ref.current) {
                return
            }

            effect()

            if (typeof ResizeObserver === 'function') {
                let resizeObserver: ResizeObserver | null = new ResizeObserver(effect)
                resizeObserver.observe(ref.current, {
                    box: 'border-box',
                })

                return function() {
                    if (resizeObserver) {
                        resizeObserver.disconnect()
                    }
                    resizeObserver = null
                }
            } else {
                window.addEventListener('resize', effect)

                return function() {
                    window.removeEventListener('resize', effect)
                }
            }
        },
        deps.concat([ref.current])
    )
}
