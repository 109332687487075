import PropTypes from 'prop-types';
import AlertComponent from './AlertComponent';
import useResizeEffect from "../../../../hooks/useResizeEffect";

const BannerComponent = props => {
    const { alerts } = props;

    function dismiss() {
        props.handleDismiss(Object.assign({}, props, { type: 'banner' }));
    }

    useResizeEffect({current: document.querySelector(".navigation-container")}, () => {
        const navContainer = document.querySelector(".navigation-container") as HTMLDivElement;
        (document.querySelector(".notification-container") as HTMLDivElement).style.top = navContainer.clientHeight + "px";
    });

    return (
        <p role="alert" className="sb-alert-banner alert">
            <span onClick={dismiss} className="fas fa-times close" role="button" aria-label="Close"/>
            <div className="alert-content">
                {
                    alerts.map((alert) =>
                        <AlertComponent key={alert.id} message={alert.message} />
                    )
                }
            </div>
        </p>
    );
}

BannerComponent.propTypes = {
    alerts: PropTypes.array.isRequired,
    handleDismiss: PropTypes.func
}

export default BannerComponent;
